/*
This is where all the functions for local state management are located. All changes to ratings are saved to the cheese
instance and these are saved locally in a minimal structure with capacitor, only when a rating is completed, the
local state is used for mutation. Will be implemented as soon as the rating logic / structure are clarified.

Each state is stored with a UserID so that you can store multiple states of different users.

*/

import {GetResult, Storage} from '@capacitor/storage';
import {findJurorFromModel, Juror, loggedInJuror} from "@/util/JuryService";
import {ResultPoints, resultPointsList, ResultPointsList} from "@/util/ResultService";
import {ref, Ref} from "vue";
import {Table} from "@/util/TableService";
import {currentContest} from "@/util/ContestService";


export const isLoading=ref(false)

export function setLoadingState(loading: boolean){
    isLoading.value = loading
}

// ### JUROR ###

export async function removeUserFromStorage(): Promise<void> {
    return await Storage.remove({key: 'logged_in'});
}

export async function removeUserRatingFromStorage(): Promise<void> {
    resultPointsList.value = []
    return await Storage.remove({key: 'resultPointsList'});
}

export function logout(){
    removeUserFromStorage().then()
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.location = window.location.origin;
}

export function isUserValid(user: any): boolean|undefined {
    Storage.get({key: 'jurySession'}).then((r) => {
        if(r.value == currentContest.value?.currentJurySessionId){
            Storage.set({key: 'jurySession', value: JSON.stringify(currentContest?.value?.currentJurySessionId) }).then()
            logout()
        }

    })

    if(new Date(user._validUntil).getTime() < new Date().getTime()){
        logout()
    }else{
        return true
    }
}

export async function loadJurorFromStorage(): Promise<void> {
    if (!loggedInJuror.value) {
        return await Storage.get({key: 'logged_in'}).then((r) => {
            if (r.value) {
                const juror = JSON.parse(r.value);
                if(isUserValid(juror)){
                    findJurorFromModel(juror._id, new Date(juror._validUntil))
                }
            }
        })
    } else {
        return Promise.resolve();
    }
}






export function saveJurorToStorage(user: Juror): void {
    const date = new Date();
    date.setTime(date.getTime() + (12*60*60*1000) )
    user._validUntil = date
    Storage.set({key: 'logged_in', value: JSON.stringify(user)}).then()
    Storage.set({key: 'jurySession', value: JSON.stringify(currentContest?.value?.currentJurySessionId) }).then()
}

// ### CHEESE ###

export function saveLocalRatingCheese(resultPointsList: Ref<ResultPointsList>): void {
    Storage.set({key: 'resultPointsList', value: JSON.stringify(resultPointsList.value)}).then()
    setLoadingState(false);
}

// ### RATING LOCK ###

export async function setLockedTable(user: number, table: string|string[]): Promise<void> {
    return await Storage.get({key: 'lockedTables'}).then((r) => {
        if (r.value) {
            const data = JSON.parse(r.value)
            const jurorTables = data.filter((val: any) => {
                return val.id == user;
            })[0];
            if(jurorTables){
                jurorTables.tables.push(table)
                Storage.set({key: 'lockedTables', value: JSON.stringify(data)}).then()
            }else{
                Storage.set({key: 'lockedTables', value: JSON.stringify([...data, {id: user, tables: [table]}])}).then()
            }
        }else{
            Storage.set({key: 'lockedTables', value: JSON.stringify([{id: user, tables: [table]}])}).then()
        }

    })
}




