import {ref} from "vue";
import {fetchResultPoints, ResultPoints,} from "@/util/ResultService";
import {loggedInJuror} from "@/util/JuryService";
import rs from "@/graphql/resultPoints.graphql";
import {client} from "@/main";
import {currentContest} from "@/util/ContestService";
import {setLoadingState} from "@/util/LocalStateService";

export type CheeseList = Array<Cheese>
export const cheeseList = ref<CheeseList>([]);

export class Cheese {
    id: number
    title: string
    coding: string
    contestCode: string
    juryDescription: string | undefined
    benefitDistributor: string | undefined
    benefitConsumer: string | undefined
    concept: string | undefined
    look: string | undefined
    productionMethod: string | undefined
    recipe: string | undefined
    taste: string | undefined
    innovation: boolean // true or false used in CheeseList.vue
    resultPoints: ResultPoints | undefined
    cheeseType: string |undefined
    maturation: string |undefined
    milkType: string |undefined
    milkTypeAddition: string |undefined
    fdm: string |undefined

    constructor(id: number, title: string, coding: string, contestCode: string, innovation: boolean,
                juryDescription?: string, benefitDistributor?: string, benefitConsumer?: string,
                concept?: string, look?: string, productionMethod?: string, recipe?: string, taste?: string,
                resultPoints?: ResultPoints) {
        this.id = id;
        this.title = title;
        this.coding = coding;
        this.contestCode = contestCode;
        this.innovation = innovation;
        this.resultPoints = resultPoints;
        this.juryDescription = juryDescription;
        this.concept = concept;
        this.look = look;
        this.productionMethod = productionMethod;
        this.recipe = recipe;
        this.taste = taste;
        this.benefitDistributor = benefitDistributor;
        this.benefitConsumer = benefitConsumer;
    }

    createResultPoints(field: string, data: number): void {
        console.log('%c |--- CREATE ---| ', 'background: #FB8C00; color: #222');
        if (loggedInJuror.value) {
            const obj: { [k: string]: any } = {
                juror: {id: loggedInJuror.value?._id},
                participant: {id: this.id},
                jurySession: {id: currentContest.value?.currentJurySessionId},
                currentPlace: 'active'
            };
            obj[field] = data
            client
                .executeMutation({
                    query: rs.createResultPoints, variables: {
                        data: obj
                    }
                })
                .then(response => {
                    this.resultPoints = new ResultPoints(response.data.createResultPoints.id, obj.participant, obj.jurySession, obj.juror, obj.points1 ?? undefined, obj.points2 ?? undefined, obj.points3 ?? undefined, obj.points4 ?? undefined, obj.comment ?? undefined, obj.answer1 ?? undefined, obj.answer2 ?? undefined, obj.answer3 ?? undefined, obj.answer4 ?? undefined, obj.answer5 ?? undefined, obj.answer6 ?? undefined, obj.answer7 ?? undefined, obj.answer8 ?? undefined, obj.answer9 ?? undefined, obj.commentInnovation ?? undefined, obj.currentPlace)
                    fetchResultPoints().then(() => {
                        setLoadingState(false);
                    })
                })
        }
    }

    productInfoString() {
        return [
            this.cheeseType,
            this.maturation,
            this.milkType,
            this.milkTypeAddition,
            this.fdm ? `${this.fdm} % F.I.T.` : null,
        ].filter((i) => !!i).join(', ');
    }
}



