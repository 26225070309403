import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';
import {createClient, defaultPlugins} from 'villus';
import {VillusAuthPlugin} from './util/authorization';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

import './registerServiceWorker'
import {loadContest} from "@/util/ContestService";


export const client = createClient({
    url: process.env.VUE_APP_UNITE_CMS_ENDPOINT,
    use: [VillusAuthPlugin, ...defaultPlugins()],
    cachePolicy: "network-only"
});


const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(client)

Promise.all([router.isReady(), loadContest()]).then(() => {
    app.mount('#app');
});
