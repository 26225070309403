import {PlateList} from "@/util/PlateService";
import {ref} from "vue";


export type TableList = Array<Table>
export const tableList = ref<TableList>([]);


export class Table {
    id: number
    title: string
    plateList: PlateList
    jurorIds: Array<number>

    constructor(id: number, title: string, plateList: PlateList, ids: Array<number>) {
        this.id = id
        this.title = title
        this.plateList = plateList
        this.jurorIds = ids
    }
}

export function getTableTitle(id: any): string {
    const table = tableList.value.find(e => e.id === id);
    return table ? table.title : `Tisch #${id}`;
}

export function getTableIndex(id: any): number {
    return Number(tableList.value.map(e => e.id).indexOf(id))+1
}
