import {ref} from "vue";
import {isUserValid, loadJurorFromStorage, saveLocalRatingCheese} from "@/util/LocalStateService";
import {loggedInJuror} from "@/util/JuryService";
import {Table} from "@/util/TableService";
import {client} from "@/main";
import rs from "@/graphql/resultPoints.graphql";
import {cheeseList} from "@/util/CheeseService";
import {currentContest} from "@/util/ContestService";
import {emitter} from "@/util/NotificationService";

export type ResultPointsList = Array<ResultPoints>
export const resultPointsList = ref<ResultPointsList>([]);
export const activeResultPointList = ref<ResultPointsList>([]);

export class ResultPoints {
    id: number
    jurorId: number | undefined
    participantsId: number
    jurySessionId: number | string | undefined

    // Cheese Points
    points1: number | undefined
    points2: number | undefined
    points3: number | undefined
    points4: number | undefined
    comment: string | undefined

    //Innovation Points
    answer1: number | undefined
    answer2: number | undefined
    answer3: number | undefined
    answer4: number | undefined
    answer5: number | undefined
    answer6: number | undefined
    answer7: number | undefined
    answer8: number | undefined
    answer9: number | undefined
    commentInnovation: string | undefined

    currentPlace: string | undefined

    [key: string]: unknown;


    constructor(id: number, participantsId: number, jurySessionId?: number|string, jurorId?: number, points1?: number, points2?: number, points3?: number, points4?: number, comment?: string, answer1?: number, answer2?: number, answer3?: number, answer4?: number, answer5?: number, answer6?: number, answer7?: number, answer8?: number, answer9?: number, commentInnovation?: string, currentPlace?: string) {
        this.id = id;
        this.jurorId = jurorId;
        this.participantsId = participantsId;
        this.jurySessionId = jurySessionId
        this.points1 = points1;
        this.points2 = points2;
        this.points3 = points3;
        this.points4 = points4;
        this.comment = comment;
        this.answer1 = answer1;
        this.answer2 = answer2;
        this.answer3 = answer3;
        this.answer4 = answer4;
        this.answer5 = answer5;
        this.answer6 = answer6;
        this.answer7 = answer7;
        this.answer8 = answer8;
        this.answer9 = answer9;
        this.commentInnovation = commentInnovation;
        this.currentPlace = currentPlace;
    }

    async save(): Promise<void> {
        console.log('SAVE')
        await this.mutateResultPoints()
    }

    async finalize(): Promise<void> {
        console.log('%c |--- FINALIZE ---| ', 'background: #E53935; color: #222');
        this.currentPlace = 'finished'
        await this.save()
    }

    resultExists(): boolean {
        return resultPointsList.value.some(elem => {
            return elem.jurorId === this.jurorId && elem.participantsId === this.participantsId;
        });
    }


    async mutateResultPoints(): Promise<void> {
        console.log('%c |--- MUTATE ---| ', 'background: #03A9F4; color: #222');
        const obj: { [k: string]: any } = {
            juror: typeof this.jurorId === 'object' ? this.jurorId : { id: this.jurorId },
            participant: typeof this.participantsId === 'object' ? this.participantsId : { id: this.participantsId },
            jurySession: {id: currentContest.value?.currentJurySessionId},
            points1: this.points1 ?? undefined,
            points2: this.points2 ?? undefined,
            points3: this.points3 ?? undefined,
            points4: this.points4 ?? undefined,
            answer1: this.answer1 ?? undefined,
            answer2: this.answer2 ?? undefined,
            answer3: this.answer3 ?? undefined,
            answer4: this.answer4 ?? undefined,
            answer5: this.answer5 ?? undefined,
            answer6: this.answer6 ?? undefined,
            answer7: this.answer7 ?? undefined,
            answer8: this.answer8 ?? undefined,
            answer9: this.answer9 ?? undefined,
            comment: this.comment ?? undefined,
            commentInnovation: this.commentInnovation ?? undefined,
            currentPlace: this.currentPlace === 'assigned' ? 'active' : this.currentPlace
        };
        await client
            .executeMutation({
                query: rs.updateResultPoints,
                variables: {
                    id: this.id,
                    data: obj
                }
            }).then(() => {
            saveLocalRatingCheese(resultPointsList)
        }).catch(() => {
            emitter.emit('reMutate', {text:'Es ist ein Verbindungsfehler aufgetreten. Daten werden erneut gesendet...', id: this.participantsId});
            }
        )
    }
}


export async function fetchResultPoints(): Promise<void> {
    console.log('%c |--- FETCH ---| ', 'background: #bada55; color: #222');
    if(loggedInJuror.value){
        isUserValid(loggedInJuror?.value)
    }
    resultPointsList.value = []
    if(currentContest?.value?.currentJurySessionId){

        await client
            .executeQuery({
                query: rs.findResultPoints,
                variables: {
                    jurySession: [currentContest?.value?.currentJurySessionId],
                    id: [loggedInJuror.value?.id],
                }
            })
            .then(response => {
                const data = response.data.findResultPoints
                if (loggedInJuror.value) {
                    const jid = loggedInJuror.value._id
                    const p = cheeseList.value
                    for (const i in p) {
                        const pi = p[i as any]
                        const r = data.filter(function (el: {jurySession: { id: number | string }; participant: { id: number} }) {
                            return el.participant.id === pi.id && el.jurySession.id === currentContest?.value?.currentJurySessionId
                        });

                        if (r[0]) {
                            const rating = r[0]
                            const resultPoints = new ResultPoints(
                                rating.id,
                                pi.id,
                                currentContest?.value?.currentJurySessionId,
                                jid,
                                rating.points1 ?? undefined,
                                rating.points2 ?? undefined,
                                rating.points3 ?? undefined,
                                rating.points4 ?? undefined,
                                rating.comment ?? undefined,
                                rating.answer1 ?? undefined,
                                rating.answer2 ?? undefined,
                                rating.answer3 ?? undefined,
                                rating.answer4 ?? undefined,
                                rating.answer5 ?? undefined,
                                rating.answer6 ?? undefined,
                                rating.answer7 ?? undefined,
                                rating.answer8 ?? undefined,
                                rating.answer9 ?? undefined,
                                rating.commentInnovation ?? undefined,
                                rating.currentPlace ?? undefined
                            )
                            resultPointsList.value.push(resultPoints)
                            pi.resultPoints = resultPoints
                            saveLocalRatingCheese(resultPointsList)
                        }
                    }
                }
            }).catch(() => {
                emitter.emit('reFetch', 'Es ist ein Verbindungsfehler aufgetreten. Daten werden erneut geladen...');
            }
        )
    }
}



export function getActiveRatings(): any {
    if (loggedInJuror.value) {
        activeResultPointList.value = resultPointsList.value.filter(elem => elem['jurorId'] == loggedInJuror.value?._id && elem['currentPlace'] == 'active')
    }
}

export function getActiveRatingsCount(): number {
    getActiveRatings()
    return activeResultPointList.value.length
}

export function getActiveRatingsPerTable(table: Table): any {
    const data = {'open': 0, 'finished': 0}
    for (const i in table.plateList) {
        if (table.plateList[i].cheese.resultPoints) {
            if (table.plateList[i].cheese.resultPoints?.currentPlace === 'finished') {
                data.finished++;
                data.open++;
            } else {
                data.open++;
            }
        } else {
            data.open++;
        }
    }
    return data
}

