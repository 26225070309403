import {ClientPluginContext} from 'villus';
import {Storage} from '@capacitor/storage';

export function VillusAuthPlugin({opContext}: ClientPluginContext) {
    opContext.headers['X-AUTH-TOKEN'] = process.env.VUE_APP_UNITE_CMS_TOKEN;
}




