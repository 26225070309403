import {ref,} from "vue";
import router from "@/router";
import {saveJurorToStorage} from "@/util/LocalStateService";

export type JurorList = Array<Juror>
export const loggedInJuror = ref<Juror | null>(null)
export const juryList = ref<JurorList>([]);


export class Juror {

    _id: number;
    _firstName: string;
    _lastName: string;
    _validUntil: Date|null;

    constructor(id: number, firstName: string, lastName: string) {
            this._id = id,
            this._firstName = firstName,
            this._lastName = lastName
            this._validUntil = null
    }

    getName() {
        return this._firstName + ' ' + this._lastName;
    }

    get id(): number {
        return this._id;
    }

    // Checks if the pw is the first three letters of the first name and the first three letters of the last name:
    // Max Mustermann= maxmus
    verifyPassword(pw: string, juror: Juror) {
        if (juror !== null) {
            const correct = juror._firstName.substr(0, 3).toLowerCase() + juror._lastName.substr(0, 3).toLowerCase();
            if (correct === pw.toLowerCase()) {
                return false
            }
        }
        return true
    }

    logInJuror(): void {
        loggedInJuror.value = this
        saveJurorToStorage(this)
        router.push('/').then()
    }
}

export function findJurorFromModel(id: number, validUntil: Date): any {
    const t = juryList.value.filter(function (el) {
        return el.id === id
    });
    console.log(t)
    if (t[0]) {
        t[0]._validUntil = validUntil
        loggedInJuror.value = t[0]
    }
}

